import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Polygon } from 'geojson';
import { AreaSearchResult, PlaceSearchResult, POISearchResult } from '@alltrails/search/types/algoliaResultTypes';
import type BoundingBox from '../types/BoundingBox';
import type ExploreServiceFilters from '../types/ExploreServiceFilters';
import type ExploreServiceSort from '../types/ExploreServiceSort';
import type TrailCompletion from '../types/TrailCompletion';
import { defaultExploreServiceSort } from '../utils/constants';
import DistanceAway from '../types/DistanceAway';
import { exploreServiceApi } from './exploreServiceApi';

type LocationResult = AreaSearchResult | PlaceSearchResult | POISearchResult;

export type ExploreServiceState = {
  boundingBox: BoundingBox | undefined;
  distanceAway: DistanceAway | undefined;
  distanceAwayIsochrone: Polygon | undefined;
  distanceAwayLoading?: boolean;
  filters: ExploreServiceFilters;
  locationResult?: AreaSearchResult | PlaceSearchResult | POISearchResult;
  mapRotation: number;
  sort: ExploreServiceSort;
  trailCompletion: TrailCompletion[];
};

export const initialState: ExploreServiceState = {
  boundingBox: undefined,
  distanceAway: undefined,
  distanceAwayIsochrone: undefined,
  distanceAwayLoading: false,
  filters: {},
  mapRotation: 0,
  sort: defaultExploreServiceSort,
  trailCompletion: []
};

export const exploreServiceSlice = createSlice({
  name: 'exploreService',
  initialState,
  reducers: {
    updateBoundingBox: (state, action: PayloadAction<BoundingBox | undefined>) => {
      state.boundingBox = action.payload;
    },
    updateDistanceAway: (state, action: PayloadAction<DistanceAway | undefined>) => {
      state.distanceAway = action.payload;
    },
    updateDistanceAwayIsochrone: (state, action: PayloadAction<Polygon | undefined>) => {
      state.distanceAwayIsochrone = action.payload;
    },
    updateDistanceAwayLoading: (state, action: PayloadAction<boolean>) => {
      state.distanceAwayLoading = action.payload;
    },
    updateFilters: (state, action: PayloadAction<ExploreServiceFilters>) => {
      state.filters = action.payload;
    },
    updateLocationResult: (state, action: PayloadAction<LocationResult | undefined>) => {
      state.locationResult = action.payload;
    },
    updateMapRotation: (state, action: PayloadAction<number>) => {
      state.mapRotation = action.payload;
    },
    updateSort: (state, action: PayloadAction<ExploreServiceSort>) => {
      state.sort = action.payload;
    },
    updateTrailCompletion: (state, action: PayloadAction<TrailCompletion[]>) => {
      state.trailCompletion = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(exploreServiceApi.endpoints.getResultsBoundingBox.matchFulfilled, (state, { payload }) => {
      if (payload.boundingBox) {
        state.boundingBox = payload.boundingBox;
        state.mapRotation = 0;
      }
    });
  }
});

export const {
  updateBoundingBox,
  updateDistanceAway,
  updateDistanceAwayIsochrone,
  updateDistanceAwayLoading,
  updateFilters,
  updateLocationResult,
  updateMapRotation,
  updateSort,
  updateTrailCompletion
} = exploreServiceSlice.actions;

export const exploreServiceReducer = exploreServiceSlice.reducer;
