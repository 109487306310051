import { ComponentProps, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import SearchInput, { type SearchInputProps } from '@alltrails/denali/components/SearchInput';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import type { OnSelect, RenderResultContent, Results, ResultBase } from '../../types/searchBoxTypes';
import { getFirstItem, getNewActiveItem } from '../../utils/resultItemUtils';
import CustomSearchResults from './results/CustomSearchResults';
import MobileSearch from './MobileSearch';
import styles from './styles/FullscreenSearchBox.module.scss';

type FullscreenSearchBoxProps<T extends ResultBase> = {
  className?: string;
  clearSelectionOnCommit?: boolean;
  commitOnEmptyQuery?: boolean;
  dropdownVariant?: 'default' | 'enveloping' | 'inline';
  hideResults?: boolean;
  iconButtonProps?: SearchInputProps['iconButtonProps'];
  isLoading?: boolean;
  mobileFocusTrapOptions?: ComponentProps<typeof MobileSearch>['focusTrapOptions'];
  onCommit: OnSelect<T>;
  onHideResults?: () => void;
  onInputFocus?: () => void;
  onMobileCancel?: () => void;
  onQueryChange: (newQuery: string) => void;
  onShowResults?: () => void;
  placeholder?: string;
  preventScrollOnResultSelection?: boolean;
  query: string;
  renderResultContent: RenderResultContent<T>;
  requireSelection?: boolean;
  results: Results<T>;
  size?: SearchInputProps['size'] | 'homepage';
  tertiaryElement?: React.ReactNode;
  testId: string;
  validateQuery?: (query: string) => boolean;
} & Pick<SearchInputProps, 'variant'>;

/**
 * An extension off of the Denali `SearchInput` component to be used inline on web & full screen on mobile.
 */
const FullscreenSearchBox = <T extends ResultBase>({
  className,
  clearSelectionOnCommit,
  commitOnEmptyQuery,
  dropdownVariant = 'default',
  hideResults,
  iconButtonProps,
  isLoading,
  mobileFocusTrapOptions,
  onCommit,
  onHideResults,
  onInputFocus,
  onMobileCancel,
  onQueryChange,
  onShowResults,
  placeholder,
  preventScrollOnResultSelection,
  query,
  renderResultContent,
  requireSelection,
  results,
  size = 'md',
  testId,
  tertiaryElement,
  validateQuery,
  variant
}: FullscreenSearchBoxProps<T>) => {
  const isMobile = useIsMobileSizedScreen();
  const [showResults, setShowResults] = useState(false);
  const [activeResult, setActiveResult] = useState<T>();

  const isValidQuery = useCallback((value: string) => !validateQuery || validateQuery(value), [validateQuery]);

  const toggleResults = useCallback(
    (show: boolean) => {
      if (show && onShowResults) {
        onShowResults();
      } else if (!show && onHideResults) {
        onHideResults();
      }
      if (!show) {
        setActiveResult(undefined);
      }
      setShowResults(show);
    },
    [onHideResults, onShowResults]
  );

  const onInputClick = useCallback(() => {
    if (isMobile && !showResults && isValidQuery(query)) {
      toggleResults(true);
    }
  }, [isMobile, isValidQuery, query, showResults, toggleResults]);

  const onCancelMobileSearch = useCallback(() => {
    onMobileCancel?.();
    toggleResults(false);
  }, [onMobileCancel, toggleResults]);

  const onBlur = useCallback(() => {
    if (!isMobile) {
      toggleResults(false);
    }
    if (commitOnEmptyQuery && !query) {
      onCommit();
    }
  }, [commitOnEmptyQuery, isMobile, onCommit, query, toggleResults]);

  const onFocus = useCallback(() => {
    onInputFocus?.();
    if (requireSelection) {
      setActiveResult(getFirstItem(results));
    }
    if (!isMobile && isValidQuery(query)) {
      toggleResults(true);
    }
  }, [isMobile, isValidQuery, onInputFocus, query, requireSelection, results, toggleResults]);

  const onChange = useCallback(
    (value: string) => {
      if (!showResults && isValidQuery(value)) {
        toggleResults(true);
      } else if (showResults && !isValidQuery(value)) {
        toggleResults(false);
      }

      onQueryChange(value);
    },
    [isValidQuery, onQueryChange, showResults, toggleResults]
  );

  const onSearchCommit: OnSelect<T> = useCallback(
    (result, additionalInfo) => {
      onCommit(result, additionalInfo);
      toggleResults(false);
      if (clearSelectionOnCommit) {
        setActiveResult(undefined);
      }
    },
    [clearSelectionOnCommit, onCommit, toggleResults]
  );

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        case 'Escape':
          toggleResults(false);
          break;
        case 'Enter':
          if (isMobile && !showResults && isValidQuery(query)) {
            toggleResults(true);
          } else if (activeResult) {
            const index = results.findIndex(result => result === activeResult);
            onSearchCommit(activeResult as T, index > -1 ? { index, query } : undefined);
          } else {
            onSearchCommit();
          }
          break;
        case 'ArrowUp':
          ev.preventDefault();
          setActiveResult(getNewActiveItem(results, activeResult, 'prev'));
          break;
        case 'ArrowDown':
          ev.preventDefault();
          setActiveResult(getNewActiveItem(results, activeResult, 'next'));
          break;
        default:
        // do nothing
      }
    },
    [activeResult, isMobile, isValidQuery, onSearchCommit, query, results, showResults, toggleResults]
  );

  useEffect(() => {
    // Avoid some weird focus scenarios if you change to mobile while the results are shown
    if (isMobile) {
      toggleResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (hideResults) {
      toggleResults(false);
    }
  }, [hideResults]);

  useEffect(() => {
    if (requireSelection) {
      setActiveResult(getFirstItem(results));
    }
  }, [requireSelection, results]);

  return (
    <div className={classNames(styles.container, styles[size], className)} role="search">
      <SearchInput
        className={classNames(styles.input, { [styles.isActive]: showResults && !isMobile })}
        iconButtonProps={iconButtonProps}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onInputClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        size={size === 'homepage' ? 'md' : size}
        testId={testId}
        value={query}
        variant={variant}
      />
      {showResults &&
        (isMobile ? (
          <MobileSearch
            activeId={activeResult?.id}
            areResultsLoading={isLoading}
            iconButtonProps={iconButtonProps}
            focusTrapOptions={mobileFocusTrapOptions}
            onCancel={onCancelMobileSearch}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onSelect={onSearchCommit}
            placeholder={placeholder}
            query={query}
            renderResultContent={renderResultContent}
            results={results}
            testId={`${testId}-mobile`}
          />
        ) : (
          <CustomSearchResults
            activeId={activeResult?.id}
            areResultsLoading={isLoading}
            className={classNames(styles.dropdown, styles[dropdownVariant])}
            onSelect={onSearchCommit}
            preventScrollOnResultSelection={preventScrollOnResultSelection}
            query={query}
            renderResultContent={renderResultContent}
            results={results}
          />
        ))}
      {tertiaryElement}
    </div>
  );
};

export default FullscreenSearchBox;
