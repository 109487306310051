import { useCallback, useMemo, useRef } from 'react';
// eslint-disable-next-line @alltrails/custom/client-navigation
import { useRouter } from 'next/navigation';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { usePublicBaseUrl } from '@alltrails/context';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';

/**
 * A hook to navigate the user back to the previous page iff they came from an internal site.
 * Otherwise, navigate them to an internal fallback path.
 * @param {string} [fallbackPath='/explore'] - The path to navigate to if the user did not come from an internal site.
 * @returns {Object}- An object containing the denoting if a user can navigating back meets the above criteria & a function to do so.
 */
export default function useNavigateBackInternal(fallbackPath: string = '/explore') {
  const languageRegionCode = useLanguageRegionCode();
  const publicBaseUrl = usePublicBaseUrl();
  const canNavigateBack = useRef(false);
  const router = useRouter();

  const navigateBack = useCallback(() => {
    const explorePath = wrapUrlSafe(fallbackPath, languageRegionCode);

    if (document.referrer.includes(publicBaseUrl) && window.history.length > 1) {
      router.back();
    } else {
      window.location.href = explorePath;
    }
  }, [fallbackPath, languageRegionCode, publicBaseUrl, router]);

  return useMemo(() => ({ canNavigateBack, navigateBack }), [canNavigateBack, navigateBack]);
}
