'use client';

import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useUser } from '@alltrails/context';
import { useExperiments, useExposureEvent } from '@alltrails/experiments';
import { getItem } from '@alltrails/local-storage';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

const PostAuthUpsellModal = dynamic(() => import('../PostAuthUpsellModal/PostAuthUpsellModal'), {
  ssr: false
});

const FourteenDays = 60 * 60 * 24 * 14 * 1000;

export default function ReturningUserUpsell() {
  const experiments = useExperiments();
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const sendExposure = useExposureEvent('web-growth-rm-returning-generic-upsell');

  useEffect(() => {
    const lastDisplayTime = getItem<number>('post-auth-upsell');

    if (user && !user.pro && ((lastDisplayTime && new Date().getTime() - lastDisplayTime >= FourteenDays) || !lastDisplayTime)) {
      sendExposure();

      if (experiments['web-growth-rm-returning-generic-upsell']?.value === 'treatment') {
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want to run this after sign up modal or google one tap updates the user which would show two upsells
  }, [experiments, sendExposure]);

  if (isOpen) {
    return (
      <PostAuthUpsellModal
        isOpen={isOpen}
        onCloseRequest={() => {
          setIsOpen(false);
        }}
        trigger={CarouselDisplayTrigger.ReturningUserPageView}
      />
    );
  }

  return null;
}
