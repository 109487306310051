import { useState, useCallback, useMemo, useEffect, ComponentProps } from 'react';
import debounce from 'lodash.debounce';
import { SearchInputProps } from '@alltrails/denali/components/SearchInput';
import useIsMounted from '@alltrails/shared/hooks/useIsMounted';
import FullscreenSearchBox from '../FullscreenSearchBox';
import { OnSelect, RenderResultContent, ResultBase, Results } from '../../types/searchBoxTypes';

export type CustomSearchBoxProps<T extends ResultBase> = {
  className?: string;
  clearOnSelect?: boolean;
  debounceMs?: number;
  fetchResultsRequest: (query: string) => void;
  onHideResults?: () => void;
  onInputFocus?: () => void;
  onShowResults?: () => void;
  onSelect: OnSelect<T>;
  renderResultContent: RenderResultContent<T>;
  results: Results<T>;
  testId: string;
  validateQuery?: (query: string) => boolean;
  value?: string;
} & Pick<ComponentProps<typeof FullscreenSearchBox>, 'dropdownVariant' | 'placeholder' | 'size' | 'variant'>;

/**
 * A search box component for UIs that require a result to be selected to commit the search.
 * Ex: Algolia search, where a user must select a result to navigate to the result's page.
 */
const CustomSearchBox = <T extends ResultBase>({
  className,
  clearOnSelect,
  debounceMs = 500,
  dropdownVariant,
  fetchResultsRequest,
  onHideResults,
  onInputFocus,
  onShowResults,
  onSelect,
  placeholder,
  renderResultContent,
  results,
  size = 'md',
  testId,
  validateQuery,
  variant,
  value = ''
}: CustomSearchBoxProps<T>) => {
  const [query, setQuery] = useState(value);
  const isMounted = useIsMounted();
  const [areResultsLoading, setAreResultsLoading] = useState(false);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  const search = useCallback(
    (searchQuery: string) => {
      if (isMounted() && (!validateQuery || validateQuery(searchQuery))) {
        fetchResultsRequest(searchQuery);
      }
    },
    [fetchResultsRequest, isMounted, validateQuery]
  );

  // Gets an initial set of results and updates results when fetchResults changes
  useEffect(() => {
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async text => {
        await search(text);
        setAreResultsLoading(false);
      }, debounceMs),
    [debounceMs, search]
  );

  const onChange = useCallback(
    (text: string) => {
      setQuery(text);
      debouncedSearch(text);
      setAreResultsLoading(true);
    },
    [debouncedSearch]
  );

  const onItemSelect: OnSelect<T> = useCallback(
    (result, additionalInfo) => {
      onSelect(result, additionalInfo);
      if (clearOnSelect) {
        setQuery('');
      }
    },
    [onSelect, clearOnSelect]
  );

  const onCancelMobileSearch = useCallback(() => {
    setQuery('');
  }, []);

  return (
    <FullscreenSearchBox
      className={className}
      dropdownVariant={dropdownVariant}
      isLoading={areResultsLoading}
      onCommit={onItemSelect}
      onHideResults={onHideResults}
      onInputFocus={onInputFocus}
      onMobileCancel={onCancelMobileSearch}
      onQueryChange={onChange}
      onShowResults={onShowResults}
      placeholder={placeholder}
      query={query}
      renderResultContent={renderResultContent}
      requireSelection
      results={results}
      size={size}
      testId={testId}
      validateQuery={validateQuery}
      variant={variant}
    />
  );
};

export default CustomSearchBox;
