import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import logExploreSearchBoxClicked from '@alltrails/analytics/events/logExploreSearchBoxClicked';
import logGuideClicked from '@alltrails/analytics/events/logGuideClicked';
import logSearchResultClicked from '@alltrails/analytics/events/logSearchResultClicked';
import logGuideIndexClicked from '@alltrails/analytics/events/logGuideIndexClicked';
import GuideSourceType from '@alltrails/analytics/enums/GuideSourceType';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import GuideIndexLocation from '@alltrails/analytics/enums/GuideIndexLocation';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { SearchType, AlgoliaResultType } from '@alltrails/shared/types/algoliaSearch';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import { usePublicBaseUrl, useUser } from '@alltrails/context';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { useExperiments, useExposureEvent } from '@alltrails/experiments';
import dynamic from 'next/dynamic';
import { OnSelect, Results } from '../../types/searchBoxTypes';
import {
  AlgoliaHit,
  AlgoliaSearchResult,
  AreaSearchResult,
  GuideMetadata,
  GuidesResult,
  NearbyResult,
  SearchRequestOptions
} from '../../types/algoliaResultTypes';
import { algoliaHitToResult, getUrl, guideMetadataToSearchResult } from '../../utils/algoliaResultUtils';
import useRecentSearches from '../../hooks/useRecentSearches';
import useAlgoliaIndex from '../../hooks/useAlgoliaIndex';
import { getAnalyticsTags, getPoiType, getSearchItemType, getSearchSelectionType } from '../../utils/algoliaAnalyticsHelpers';
import createWeightedResultList from '../../utils/createWeightedResults';
import CustomSearchBox, { CustomSearchBoxProps } from '../CustomSearchBox/CustomSearchBox';
import AlgoliaSearchResultContent from './AlgoliaSearchResultContent';
import styles from './styles/styles.module.scss';

const AlgoliaGuidesCarousel = dynamic(() => import('./AlgoliaGuidesCarousel'));

type Result = AlgoliaSearchResult | GuidesResult | NearbyResult;

export type AlgoliaSearchBoxProps = {
  configs: AlgoliaConfigs;
  defaultRadiusMeters?: number;
  getAreaDistance?: (area: AlgoliaSearchResult | NearbyResult) => void;
  hiddenResults?: string[];
  navigateOnSelect?: boolean;
  numResults?: number;
  onNearbyClick?: () => void;
  onResultSelect?: OnSelect<AlgoliaSearchResult>;
  onSearch?: (response?: { queryID?: string }) => void;
  searchLatLng?: [number, number];
  searchOnEmptyQuery?: boolean;
  searchOrigin?: SearchOrigin;
  searchTypes: 'all' | Exclude<AlgoliaResultType, 'map' | 'track'>[] | ('map' | 'track')[];
  suppressAlgoliaAnalytics?: boolean;
  weightResults?: boolean;
} & Pick<
  CustomSearchBoxProps<AlgoliaSearchResult>,
  'className' | 'clearOnSelect' | 'debounceMs' | 'dropdownVariant' | 'placeholder' | 'size' | 'testId' | 'variant' | 'value'
>;

const searchAll = (searchTypes: AlgoliaSearchBoxProps['searchTypes']) => searchTypes === 'all' || searchTypes.length === 0;
const nearbyResult: NearbyResult = { id: 'nearby', type: 'nearby', searchType: SearchType.Nearby };

const removeHiddenHits = (hits: AlgoliaHit[], hiddenObjectIds?: string[]) => {
  if (hiddenObjectIds?.length) {
    return hits.filter(hit => hit && !hiddenObjectIds.includes(hit.objectID));
  }
  return hits;
};

const AlgoliaSearchBox = ({
  configs,
  defaultRadiusMeters,
  getAreaDistance,
  hiddenResults,
  navigateOnSelect = false,
  numResults = 50,
  onNearbyClick,
  onResultSelect,
  onSearch,
  searchLatLng,
  searchOnEmptyQuery,
  searchOrigin,
  searchTypes = 'all',
  suppressAlgoliaAnalytics,
  weightResults,
  ...customSearchBoxProps
}: AlgoliaSearchBoxProps) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const isMobile = useIsMobileSizedScreen();
  const { getRecentHits, addRecent } = useRecentSearches();
  const showNearbyResult = useMemo(() => !!onNearbyClick, [onNearbyClick]);
  const [results, setResults] = useState<Results<Result>>(showNearbyResult ? [nearbyResult] : []);
  const index = useAlgoliaIndex(configs, searchTypes);
  const [currentQuery, setCurrentQuery] = useState<string>('');
  const useNewSearch = useExperiments()['web-disco-rm-global-nav']?.value === 'treatment_b';
  const sendExposure = useExposureEvent('web-disco-rm-global-nav');
  const baseUrl = usePublicBaseUrl();

  useEffect(() => {
    sendExposure();
  }, [sendExposure]);

  // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
  const facetFilters = useMemo(() => {
    if (searchAll(searchTypes)) {
      return ['type:-track', 'type:-map'];
    }
    const typedSearchTypes = searchTypes as AlgoliaResultType[]; // This gets type to never[] since there isn't overlap between the constant possibilities
    if (typedSearchTypes.length === 1) {
      return [`type:${searchTypes[0]}`];
    }
    return [typedSearchTypes.map(type => `type:${type}`)];
  }, [searchTypes]);

  const updateResults = useCallback(
    (searchType: SearchType, includeNearby: boolean, hits: AlgoliaHit[], hitsHeader?: string) => {
      const filteredHits = removeHiddenHits(
        hits.filter(hit => Boolean(hit)),
        hiddenResults
      );
      let filteredResults: Result[] = filteredHits.map(hit => algoliaHitToResult(hit, searchType));
      // If the first result is an area with guides, remove guides for that area from the remaining list of results
      // and move them immediately below the area result
      const includeGuides = searchAll(searchTypes) || (searchTypes as AlgoliaResultType[]).includes('guide');
      if (includeGuides && filteredResults.length && filteredResults[0].type === 'area' && filteredResults[0].guides?.length) {
        const areaResult = filteredResults[0] as AreaSearchResult;
        filteredResults = filteredResults.filter(result => !(result.type === 'guide' && result.area_id === areaResult.ID));
        if (areaResult.guides?.length === 1) {
          filteredResults.splice(1, 0, algoliaHitToResult({ ...areaResult.guides[0], type: 'guide' }, searchType));
        } else if ((areaResult.guides?.length || 0) > 1) {
          filteredResults.splice(1, 0, {
            type: 'guides',
            areaName: areaResult.short_name || areaResult.name,
            id: `${areaResult.ID}-guides`,
            guides: areaResult.guides as GuideMetadata[],
            containerClassName: styles.guidesContainer,
            searchType
          });
        }
      }
      const newResults: Results<Result> = [];
      if (includeNearby && showNearbyResult) {
        newResults.push(nearbyResult);
      }
      if (hits.length) {
        if (hitsHeader) {
          newResults.push({ text: hitsHeader });
        }
        newResults.push(...filteredResults);
      }
      setResults(newResults);
    },
    [hiddenResults, showNearbyResult]
  );

  const fetchResultsRequest = useCallback(
    async (query: string) => {
      setCurrentQuery(query);
      const baseOptions: SearchRequestOptions = {
        hitsPerPage: numResults,
        facetFilters,
        ...(suppressAlgoliaAnalytics ? {} : { analyticsTags: getAnalyticsTags({ user, isMobile, searchOrigin, languageRegionCode, query }) })
      };

      const searchOptions: SearchRequestOptions = {
        ...baseOptions,
        getRankingInfo: true,
        aroundLatLngViaIP: true,
        // rank uniformly based on geo in these bands: 0-50mi, 50-200mi, 200+
        aroundPrecision: [
          { from: 0, value: 80000 },
          { from: 80000, value: 320000 },
          { from: 320000, value: 50000000 }
        ],
        aroundRadius: 'all'
      };

      if (searchOnEmptyQuery && !query) {
        if (searchLatLng && defaultRadiusMeters && searchLatLng?.length === 2) {
          const latLngSearchOptions: SearchRequestOptions = {
            ...baseOptions,
            getRankingInfo: true,
            aroundLatLng: searchLatLng?.join(','),
            aroundRadius: defaultRadiusMeters
          };
          index
            .search(query, latLngSearchOptions)
            .then(response => {
              if (weightResults) {
                // drop down sorts by default lat/lng
                const sortedResults = createWeightedResultList(response.hits, searchLatLng, defaultRadiusMeters);
                updateResults(SearchType.Suggestion, false, sortedResults);
              } else {
                updateResults(SearchType.Suggestion, false, response.hits);
              }
            })
            .catch(e => {
              window?.Bugsnag?.notify(e);
              updateResults(SearchType.Suggestion, false, []);
            });
        } else {
          index
            .search(query, searchOptions)
            .then(response => {
              updateResults(SearchType.Suggestion, false, response.hits);
            })
            .catch(e => {
              window?.Bugsnag?.notify(e);
              updateResults(SearchType.Suggestion, false, []);
            });
        }
      } else if (query) {
        index
          .search(query, searchOptions)
          .then(response => {
            if (onSearch) {
              onSearch({ queryID: response.queryID });
            }
            updateResults(SearchType.Suggestion, false, response.hits);
          })
          .catch(e => {
            window?.Bugsnag?.notify(e);
            updateResults(SearchType.Suggestion, false, []);
          });
      } else {
        const recentHits = getRecentHits(searchTypes);
        if (recentHits.length) {
          index
            .getObjects(recentHits.map(hit => hit.objectID))
            .then(response => {
              updateResults(SearchType.Recent, true, response.results as AlgoliaHit[], intl.formatMessage({ defaultMessage: 'Recent searches' }));
            })
            .catch(e => {
              window?.Bugsnag?.notify(e);
              updateResults(SearchType.Recent, true, []);
            });
        } else {
          updateResults(SearchType.Nearby, true, []);
        }
      }
    },
    [
      defaultRadiusMeters,
      facetFilters,
      getRecentHits,
      index,
      intl,
      isMobile,
      languageRegionCode,
      numResults,
      onSearch,
      searchLatLng,
      searchOnEmptyQuery,
      searchOrigin,
      searchTypes,
      suppressAlgoliaAnalytics,
      updateResults,
      user,
      weightResults
    ]
  );

  const onInputFocus = useCallback(() => {
    if (searchOrigin) {
      const { href, pathname } = window.location;
      logExploreSearchBoxClicked({ search_origin: searchOrigin, href, path: pathname });
    }
  }, [searchOrigin]);

  const onSelect: OnSelect<Result> = useCallback(
    (result, additionalInfo) => {
      if (!result) {
        return;
      }
      if (result.type === 'guides') {
        // do nothing
      } else if (result.type === 'nearby') {
        onNearbyClick?.();
      } else {
        addRecent({ objectID: result.objectID, type: result.type });
        if (searchOrigin) {
          logSearchResultClicked({
            click_position: additionalInfo?.index,
            content_id: result.id,
            item_type: getSearchItemType(result.type),
            location: searchOrigin,
            poi_type: result.type === 'poi' ? getPoiType(result) : undefined,
            query_length: additionalInfo?.query?.length,
            selection_type: getSearchSelectionType(result.searchType)
          });
        }
        if (result.type === 'guide') {
          logGuideClicked({
            guide_id: String(result.ID),
            source_type: GuideSourceType.Search,
            vertical_index: additionalInfo?.index
          });
        }
        if (result.type === 'page' && result.subtype === 'guides') {
          logGuideIndexClicked({
            guide_index_location: GuideIndexLocation.Search
          });
        }
        if (onResultSelect) {
          onResultSelect(result, additionalInfo);
        }
        if (navigateOnSelect && typeof window !== 'undefined') {
          window.location.assign(`${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`);
        }
      }
    },
    [baseUrl, addRecent, languageRegionCode, navigateOnSelect, onNearbyClick, onResultSelect, searchOrigin, useNewSearch]
  );

  const onGuideSelect = useCallback(
    (guide: GuideMetadata, index: number, searchType?: SearchType) => {
      logGuideClicked({
        guide_id: String(guide.ID),
        source_type: GuideSourceType.Search,
        horizontal_index: index,
        vertical_index: 1 // The guides carousel always renders as the 2nd element
      });

      const result = guideMetadataToSearchResult(guide, searchType);
      if (onResultSelect) {
        onResultSelect(result);
      }
      if (navigateOnSelect) {
        window.location.assign(`${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`);
      }
    },
    [baseUrl, languageRegionCode, navigateOnSelect, onResultSelect, useNewSearch]
  );

  return (
    <CustomSearchBox
      {...customSearchBoxProps}
      fetchResultsRequest={fetchResultsRequest}
      onInputFocus={onInputFocus}
      onSelect={onSelect}
      renderResultContent={result =>
        result.type === 'guides' ? (
          <AlgoliaGuidesCarousel onGuideSelect={onGuideSelect} result={result} />
        ) : (
          <AlgoliaSearchResultContent
            hideResultType={Array.isArray(searchTypes) && searchTypes.length === 1}
            result={result}
            url={result.type === 'nearby' ? undefined : `${baseUrl}${getUrl(result, languageRegionCode, useNewSearch)}`}
            query={currentQuery}
            searchOnEmptyQuery={searchOnEmptyQuery}
            getAreaDistance={getAreaDistance}
          />
        )
      }
      results={results}
    />
  );
};

export default AlgoliaSearchBox;
