import { useEffect, useState, useMemo, useCallback, lazy } from 'react';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { isCompleted, isSaved, isVerified as isVerifiedUtil } from '@alltrails/modules/Lists/listUtils';
import { type ListsState, setListsAndListItems } from '@alltrails/redux/slices/lists';
import { ListItem } from '@alltrails/shared/types/lists';
import { PrivacyPolicyOptions } from '@alltrails/shared/types/privacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { NoSsrSuspense } from '@alltrails/core';
import { type Context, useUser } from '@alltrails/context';
import { useModal } from '@alltrails/modal';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import { AnalyticsInfo } from '@alltrails/modules/Lists/listAmplitudeHelpers';
import getListsAndListItems from '../utils/requests';

const ReduxSaveToListModal = lazy(() => import('../components/ReduxSaveToListModal'));
const SignUpModal = lazy(() => import('@alltrails/user-auth').then(module => ({ default: module.SignUpModal })));

type ItemType = ListItem['type'];

type SignUpInfo = {
  onSuccess?: () => void;
};

/**
 * @param signUpInfo
 * @param preventAutoInitialize boolean to prevent list fetching on successful sign in. NOTE: setting `true` may require you to manually initialize lists.
 * @param analyticsInfo additional analytics info to be passed to the modal
 * @returns
 */
const useReduxListItems = (signUpInfo?: SignUpInfo, preventAutoInitialize?: boolean, analyticsInfo?: AnalyticsInfo) => {
  const dispatch = useDispatch();
  const currentUser = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const { listItems } = useSelector((state: { lists: ListsState; context: Context }) => ({
    listItems: state.lists.listItems,
    currentUser: state.context.currentUser,
    languageRegionCode: state.context.languageRegionCode
  }));
  const [{ id, type, contentPrivacy }, setIdTypeAndPrivacy] = useState<{ id: number; type: ItemType; contentPrivacy?: PrivacyPolicyOptions }>({
    id: 0,
    type: '' as ItemType,
    contentPrivacy: '' as PrivacyPolicyOptions
  });
  const [shouldOpenListModal, setShouldOpenListModal] = useState(false);

  const { modal, openModal } = useModal(
    ReduxSaveToListModal,
    useMemo(() => ({ id, type, contentPrivacy, analyticsInfo }), [id, type, contentPrivacy, analyticsInfo])
  );

  const onSuccess = useCallback(async () => {
    if (preventAutoInitialize) {
      setShouldOpenListModal(true);
    } else {
      const payload = await getListsAndListItems();
      dispatch(setListsAndListItems(payload));
      openModal();
    }

    signUpInfo?.onSuccess?.();
  }, [dispatch, openModal, preventAutoInitialize, signUpInfo]);

  const { modal: signUpModal, openModal: openSignupModal } = useModal(
    SignUpModal,
    useMemo(
      () => ({ autoClose: true, isOpen: true, languageRegionCode, onSuccess, trigger: CarouselDisplayTrigger.Favorite }),
      [languageRegionCode, onSuccess]
    )
  );

  const handleFavoriteClick = useCallback(
    (itemType: ItemType, itemId: number, itemPrivacy?: PrivacyPolicyOptions) => {
      // Update state before opening the signup modal so that if signup is successful we can automatically open the save to list modal
      setIdTypeAndPrivacy({ id: itemId, type: itemType, contentPrivacy: itemPrivacy });

      if (!currentUser) {
        openSignupModal();
        return;
      }

      openModal();
    },
    [currentUser, openModal, openSignupModal]
  );

  useEffect(() => {
    if (shouldOpenListModal && !!listItems) {
      openModal();
      setShouldOpenListModal(false);
    }
  }, [listItems, openModal, shouldOpenListModal]);

  const isFavorite = useCallback((itemType: string, itemId: number) => isSaved(listItems, itemType, itemId), [listItems]);
  const isComplete = useCallback((itemType: string, itemId: number) => isCompleted(listItems, itemType, itemId), [listItems]);
  const isVerified = useCallback((itemType: string, itemId: number) => isVerifiedUtil(listItems, itemType, itemId), [listItems]);
  return {
    isFavorite,
    isComplete,
    isVerified,
    handleFavoriteClick,
    listModal: <NoSsrSuspense fallback={null}>{modal}</NoSsrSuspense>,
    signUpModal: <NoSsrSuspense fallback={null}>{signUpModal}</NoSsrSuspense>
  };
};

export default useReduxListItems;
