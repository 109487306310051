'use client';

import logDirectionsButtonTapped from '@alltrails/analytics/events/logDirectionsButtonTapped';
import logPrintMapButtonClicked from '@alltrails/analytics/events/logPrintMapButtonClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import MapLocation from '@alltrails/analytics/enums/MapLocation';
import IconButton from '@alltrails/denali/components/IconButton';
import Car from '@alltrails/denali/icons/Car';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import Print from '@alltrails/denali/icons/Print';
import Share from '@alltrails/denali/icons/Share';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { useIntl } from 'react-intl';
import { useUser } from '@alltrails/context';
import { useState } from 'react';
import { ShareObject } from '@alltrails/sharing';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import dynamic from 'next/dynamic';
import { useModal } from '../../ShareModal/ShareModalContext';
import styles from './DesktopButtons.module.scss';

const SignUpModal = dynamic(() => import('@alltrails/user-auth').then(module => ({ default: module.SignUpModal })), { ssr: false });

type Props = {
  directionsLink?: string;
  printLink?: string;
  shareObject: ShareObject;
  id: number;
  pageType?: PageType;
};

const DesktopButtons = ({ directionsLink, printLink, shareObject, id, pageType }: Props) => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const { openModal } = useModal();
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  return (
    <div className={styles.desktopButtonsContainer}>
      {printLink && (
        <div className={styles.iconContainer}>
          <IconButton
            icon={{ Component: Print }}
            linkInfo={{ href: wrapUrlSafe(printLink, languageRegionCode), prefetch: false }}
            onClick={() => logPrintMapButtonClicked({ map_location: MapLocation.LocationPage, trail_id: id })}
            title={intl.formatMessage({ defaultMessage: 'Print map' })}
            testId="location-page-print"
            size="md"
          />
          <PlusBadge className={styles.plusBadge} size="sm" />
        </div>
      )}
      {shareObject && (
        <IconButton
          icon={{ Component: Share }}
          onClick={openModal}
          title={intl.formatMessage({ defaultMessage: 'Share' })}
          testId="location-page-share"
          size="md"
        />
      )}
      {directionsLink && (
        <IconButton
          icon={{ Component: Car }}
          {...(user ? { linkInfo: { href: directionsLink, target: '_blank', rel: ['noopener', 'noreferrer'], outsideOfMugen: true } } : {})}
          onClick={() => {
            logDirectionsButtonTapped({ id, page_type: pageType });

            if (!user) {
              setIsSignUpModalOpen(true);
            }
          }}
          title={intl.formatMessage({ defaultMessage: 'Directions' })}
          testId="location-page-directions"
          size="md"
        />
      )}
      {isSignUpModalOpen && (
        <SignUpModal
          closeModal={() => setIsSignUpModalOpen(false)}
          isOpen={isSignUpModalOpen}
          onSuccess={() => {
            setIsSignUpModalOpen(false);
            window.open(directionsLink, '_blank', 'noopener noreferrer');
          }}
          trigger={CarouselDisplayTrigger.LocationDirections}
        />
      )}
    </div>
  );
};

export default DesktopButtons;
