import { baseApi } from '@alltrails/redux-helpers';
import { AreaSearchResult, AreaType, TrailSearchResult } from '@alltrails/search/types/algoliaResultTypes';
import ExploreServicePayload from '../types/ExploreServicePayload';
import ExploreServiceResponse, { type ExploreServiceSearchResult } from '../types/ExploreServiceResponse';

const url = '/api/alltrails/explore/v1/search';

export const exploreServiceApi = baseApi.enhanceEndpoints({ addTagTypes: ['Trails'] }).injectEndpoints({
  endpoints: builder => ({
    getAreaTrails: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'location'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getLocationData: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'filters' | 'limit' | 'location' | 'page' | 'recordAttributesToRetrieve' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getParks: builder.mutation<
      Pick<ExploreServiceResponse<AreaSearchResult>, 'searchResults'>,
      Pick<ExploreServicePayload, 'limit' | 'location'> & { areaTypes?: AreaType[] }
    >({
      query: ({ areaTypes, limit, location }) => {
        const data: ExploreServicePayload = {
          filters: {
            collections: ['parks'],
            ...(areaTypes && { areaType: areaTypes })
          },
          ...(limit && { limit }),
          location,
          resultsToInclude: ['searchResults']
        };
        return { url, data, method: 'post' };
      }
    }),
    getResults: builder.query<
      Pick<ExploreServiceResponse<ExploreServiceSearchResult>, 'searchResults' | 'summary'>,
      Pick<ExploreServicePayload, 'filters' | 'location' | 'recordTypesToReturn' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = {
          ...payload,
          resultsToInclude: ['searchResults', 'summary']
        };
        return { url, data, method: 'post' };
      },
      keepUnusedDataFor: 3, // seconds
      providesTags: ['Trails']
    }),
    getResultsBoundingBox: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults' | 'summary'>,
      Pick<ExploreServicePayload, 'expandSearch' | 'filters' | 'location' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = {
          ...payload,
          resultsToInclude: ['searchResults', 'boundingBox']
        };
        return { url, data, method: 'post' };
      },
      invalidatesTags: ['Trails'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { expandSearch, ...rest } = args;
        const { data } = await queryFulfilled;
        rest.location = { ...data.boundingBox, mapRotation: 0 };
        dispatch(exploreServiceApi.util.upsertQueryData('getResults', rest, data));
      }
    })
  })
});

export const { useGetAreaTrailsMutation, useGetLocationDataMutation, useGetParksMutation, useGetResultsQuery, useGetResultsBoundingBoxMutation } =
  exploreServiceApi;
