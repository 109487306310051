'use client';

import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import type { Experiments } from '@alltrails/experiments';
// eslint-disable-next-line @alltrails/custom/react-imports
import React, { Fragment, PropsWithChildren } from 'react';
import { createIntl } from '@formatjs/intl';
import { Bootstrap } from '@alltrails/networking';
import { type ListsState } from '@alltrails/redux/slices/lists';
import { setGlobalFetchHeaders } from '@alltrails/core';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import Bugsnag from '@bugsnag/js';
import { startClientBugsnag } from '@alltrails/stability';
import { type MapState } from '@alltrails/maps';
import { buildStore } from '../utils/redux';
import { setupListeners } from '@/utils/listenerMiddleware';

type Props = {
  bootstrap: Bootstrap;
  browser: UserAgentBrowser;
  experiments: Experiments;
  lists: ListsState;
  map: MapState;
  messages: ReturnType<typeof createIntl>['messages'];
  locale: ReturnType<typeof createIntl>['locale'];
  irclickid?: string;
};

setupListeners();
startClientBugsnag();
const plugin = Bugsnag.getPlugin('react');
const ErrorBoundary = plugin ? plugin.createErrorBoundary(React) : Fragment;

export default function CustomProvider({
  bootstrap,
  browser,
  experiments,
  lists,
  map,
  messages,
  locale,
  children,
  irclickid
}: PropsWithChildren<Props>) {
  const languageRegionCode = useLanguageRegionCode();
  setGlobalFetchHeaders({ languageRegionCode });
  const store = buildStore({
    algoliaConfigs: bootstrap.algoliaConfig,
    amplitudeAnalyticsSettings: bootstrap.amplitudeAnalyticsSettings,
    browser,
    context: bootstrap.context,
    currencyCode: bootstrap.currencyCode,
    experiments,
    features: bootstrap.features,
    irclickid,
    lists,
    map,
    plan: bootstrap.plan,
    requestCountryCode: bootstrap.requestCountryCode
  });

  return (
    <ErrorBoundary>
      <IntlProvider messages={messages} locale={locale}>
        <Provider store={store}>{children}</Provider>
      </IntlProvider>
    </ErrorBoundary>
  );
}
