import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import AvatarStack from '@alltrails/denali/components/AvatarStack';
import Review from '@alltrails/shared/types/review';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { LinkInfo } from '@alltrails/denali/types';
import styles from './Reviews.module.scss';
import { VisitorStats } from '@/types/locationDetailsPages/shared';

type Props = {
  reviews: Review[];
  visitorStats: VisitorStats;
};

const SocialProofing = ({ reviews, visitorStats }: Props) => {
  const languageRegionCode = useLanguageRegionCode();
  const avatars = reviews.reduce(
    (acc, review) => {
      const userId = Number(review.user.id);
      const userName = `${review.user.firstName} ${review.user.lastName}`;
      const linkInfo = {
        href: wrapUrlSafe(`/members/${review.user.slug}`, languageRegionCode)
      };
      const hasPlus = review.user.pro;
      return acc.some(a => a.userId === userId) ? acc : acc.length === 3 ? acc : [...acc, { userId, userName, linkInfo, hasPlus, onClick: () => {} }];
    },
    [] as { userId: number; userName: string; linkInfo: LinkInfo; hasPlus: boolean; onClick: () => void }[]
  );

  return (
    <div className={styles.visitorStatsContainer}>
      <AvatarStack size="sm" avatars={avatars} totalAvatars={3} testId="avatars" maxAvatars={3} />
      <div className={styles.visitorStats}>
        <span className={styles.visitorsCount}> {visitorStats.visitorsCount} </span>
        <span className={styles.busiestMonth}> {visitorStats.busiestMonth} </span>
      </div>
    </div>
  );
};

export default SocialProofing;
