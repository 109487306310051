import { ComponentProps, ReactNode } from 'react';
import type { Person } from 'schema-dts';
import Avatar from '@alltrails/denali/components/Avatar';
import type User from '@alltrails/shared/types/User';
import { User as BelayUser } from '@alltrails/shared/types/belay';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import { JsonLdScript } from '@alltrails/core';
import EntityLabel from '../EntityLabel';
import styles from './UserLabel.module.scss';

export type UserLabelProps = {
  baseUrl?: string;
  className?: string;
  eagerLoadImage?: boolean;
  nameExtension?: ReactNode;
  onClick?: () => void;
  secondaryText?: ComponentProps<typeof EntityLabel>['secondaryText'];
  tertiaryText?: ComponentProps<typeof EntityLabel>['tertiaryText'];
  testIdPrefix?: string;
  user: User | BelayUser;
  userLabelClassName?: string;
  userConnectionLink?: JSX.Element;
  privacyElement?: JSX.Element;
  jsonLdPersonId?: string;
  ratingValue?: number;
};

const UserLabel = ({
  baseUrl,
  className,
  eagerLoadImage,
  nameExtension,
  onClick,
  secondaryText,
  tertiaryText,
  testIdPrefix,
  user,
  userLabelClassName,
  userConnectionLink,
  privacyElement,
  ratingValue,
  jsonLdPersonId
}: UserLabelProps): JSX.Element => {
  const userFullName = fullName(user);
  const href = `/members/${user.slug}`;
  const testIdPrefixWithName = testIdPrefix ? `${testIdPrefix}-${userFullName}` : userFullName;

  return (
    <>
      {jsonLdPersonId && (
        <JsonLdScript<Person>
          data={{
            '@type': 'Person',
            '@id': jsonLdPersonId,
            name: userFullName,
            url: href
          }}
        />
      )}
      <EntityLabel
        avatar={
          <Avatar
            className={styles.avatar}
            baseUrl={baseUrl}
            eager={eagerLoadImage}
            linkInfo={{ href, target: '_blank', rel: ['nofollow'] }}
            hasPlus={user?.pro}
            onClick={onClick}
            testId={`${testIdPrefix}-image`}
            userId={user.id as number}
            userName={userFullName}
          />
        }
        className={className}
        link={{ href, rel: ['nofollow'] }}
        nameExtension={nameExtension}
        onClick={onClick}
        secondaryText={secondaryText}
        socialLink={userConnectionLink}
        tertiaryText={tertiaryText}
        testIdPrefix={testIdPrefixWithName}
        title={userFullName}
        titleClassName={userLabelClassName}
        privacyElement={privacyElement}
        ratingValue={ratingValue}
      />
    </>
  );
};

export default UserLabel;
