import { ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';
import { LinkInfo } from '../../types';
import LinkInfoRenderer from '../LinkInfoRenderer';
import styles from './styles/styles.module.scss';

type LinkButtonOrDivProps = {
  children: ReactNode;
  className?: string;
  linkInfo?: LinkInfo;
  onClick?: (e?: MouseEvent<HTMLElement>) => void;
  testId: string;
  stopPropagation?: boolean;
};

// Used to render a basic link, button, or div wrapper, depending on the values of linkInfo and onClick
const LinkButtonOrDiv = ({ children, className, linkInfo, onClick, testId, stopPropagation = false }: LinkButtonOrDivProps): JSX.Element => {
  const classes = classNames(className, styles.container);

  const onElementClick = (e: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };

  if (linkInfo) {
    return (
      <LinkInfoRenderer className={classes} linkInfo={linkInfo} onClick={onElementClick} testId={testId}>
        {children}
      </LinkInfoRenderer>
    );
  }

  if (onClick) {
    return (
      <button className={classes} onClick={onElementClick} data-testid={testId} type="button">
        {children}
      </button>
    );
  }

  return (
    <span className={classes} data-testid={testId}>
      {children}
    </span>
  );
};

export default LinkButtonOrDiv;
