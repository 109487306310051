'use client';

import IconButton from '@alltrails/denali/components/IconButton';
import ArrowSq from '@alltrails/denali/icons/ArrowSq';
import Car from '@alltrails/denali/icons/Car';
import Share from '@alltrails/denali/icons/Share';
import logDirectionsButtonTapped from '@alltrails/analytics/events/logDirectionsButtonTapped';
import PageType from '@alltrails/analytics/enums/PageType';
import { useIntl } from 'react-intl';
import { ShareObject } from '@alltrails/sharing';
import { useUser } from '@alltrails/context';
import useNavigateBackInternal from '@alltrails/layout/hooks/useNavigateBackInternal';
import { useState } from 'react';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import dynamic from 'next/dynamic';
import { useModal } from '../../ShareModal/ShareModalContext';
import styles from './MobileButtons.module.scss';

const SignUpModal = dynamic(() => import('@alltrails/user-auth').then(module => ({ default: module.SignUpModal })), { ssr: false });

type Props = {
  directionsLink?: string;
  shareObject: ShareObject;
  id: number;
  pageType?: PageType;
};

const MobileButtons = ({ directionsLink, shareObject, id, pageType }: Props) => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const { openModal } = useModal();
  const intl = useIntl();
  const user = useUser();
  const { canNavigateBack, navigateBack } = useNavigateBackInternal();

  return (
    <div className={styles.mobileButtonsContainer} data-testid="mobile-buttons-container">
      <IconButton
        icon={{ Component: ArrowSq, orientation: 'left' }}
        onClick={navigateBack}
        title={canNavigateBack ? intl.formatMessage({ defaultMessage: 'Back' }) : intl.formatMessage({ defaultMessage: 'Back to Explore' })}
        testId="location-page-back"
        variant="transparent"
        size="sm"
        className={styles.button}
      />
      <div className={styles.rightButtonsContainer}>
        {directionsLink && (
          <IconButton
            icon={{ Component: Car }}
            {...(user ? { linkInfo: { href: directionsLink, target: '_blank', rel: ['noopener', 'noreferrer'], outsideOfMugen: true } } : {})}
            onClick={() => {
              logDirectionsButtonTapped({ id, page_type: pageType });

              if (!user) {
                setIsSignUpModalOpen(true);
              }
            }}
            title={intl.formatMessage({ defaultMessage: 'Directions' })}
            testId="location-page-directions"
            variant="transparent"
            size="sm"
            className={styles.button}
          />
        )}
        {shareObject && (
          <IconButton
            icon={{ Component: Share }}
            onClick={openModal}
            title={intl.formatMessage({ defaultMessage: 'Share' })}
            testId="location-page-share"
            variant="transparent"
            size="sm"
            className={styles.button}
          />
        )}
      </div>
      {isSignUpModalOpen && (
        <SignUpModal
          closeModal={() => setIsSignUpModalOpen(false)}
          isOpen={isSignUpModalOpen}
          onSuccess={() => {
            setIsSignUpModalOpen(false);
            window.open(directionsLink, '_blank', 'noopener noreferrer');
          }}
          trigger={CarouselDisplayTrigger.LocationDirections}
        />
      )}
    </div>
  );
};

export default MobileButtons;
