import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';
import { setContext } from '@alltrails/context';
import { getListsAndListItems } from '@alltrails/lists';
import { setListsAndListItems } from '@alltrails/redux/slices/lists';
import type { RootState, AppDispatch } from './redux';

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

const onUserLogin = async (action, listenerApi) => {
  if (action.payload.currentUser) {
    const userLists = await getListsAndListItems();
    listenerApi.dispatch(setListsAndListItems(userLists)); // dispatch lists info
  }
};

export const setupListeners = () => {
  startAppListening({ actionCreator: setContext, effect: onUserLogin });
};
